@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400&family=Sora:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.intera.dev/_/plus+jakarta+sans");
$theme-colors: (
  "site-primary": #cd5029,
  "site-gray": #aa9d91,
);
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  //4px
  2:
    (
      $spacer * 0.5,
    ),
  //8px
  3: $spacer,
  //16px
  4:
    (
      $spacer * 1.5,
    ),
  //24px
  5:
    (
      $spacer * 3,
    ),
  //48px
  6:
    (
      $spacer * 4,
    ),
  //64px
  7:
    (
      $spacer * 5,
    ),
  //80px
  8:
    (
      $spacer * 6.25,
    ),
  //100px
  9:
    (
      $spacer * 7.5,
    ),
  //120px
  10:
    (
      $spacer * 9.375,
    ),
  //150px
  -1: (
      $spacer * -0.25,
    ),
  //4px
  -2: (
      $spacer * -0.5,
    ),
  //8px
  -3: -$spacer,
  //16px
  -4: (
      $spacer * -1.5,
    ),
  //24px
  -5: (
      $spacer * -3,
    ),
  //48px
  -6: (
      $spacer * -4,
    ),
  //64px
  -7: (
      $spacer * -5,
    ),
  //80px
  -8: (
      $spacer * -6.25,
    ),
  //100px
  -9: (
      $spacer * -7.5,
    ),
  //120px
  -10: (
      $spacer * -9.375,
    ) //150px,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,
);
@import "node_modules/bootstrap/scss/bootstrap";

*:focus {
  border-color: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.wrapWidth {
  width: 800px;
  margin: 0 auto;
  @media (max-width: 1400px) {
    width: 100%;
    margin: 0 auto;
  }
  @media (max-width: 1400px) {
    width: 100%;
    padding: 0px 3%;
  }
  @media (max-width: 1020px) {
   width: 100%;
   padding: 0px 3%;
 }
  @media (max-width: 700px) {
 width: 100%;
 padding: 0px 3%;
}
 @media (max-width: 480px) {
  padding: 0px 5%;
 }
}

.header {
  color: #f4f6f8e3;
  position: fixed;
  width: 100%;
  padding: 10px 0px;
  z-index: 1;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:100%;
  object-fit: cover;
  background: linear-gradient(90deg, rgba(17,83,201,1) 3%, rgba(12,24,81,1) 100%);

// imagen logo header

  .img-responsive {
    max-width: 25%;
    height: auto;
    @media (max-width: 480px) {
      max-width: 60%;
      height: auto;
    }
  }

}
.cleanbtn {
  border: none;
  outline: none;
  background: none;
}


button {
  background: #EBEDF2 !important;
  color: #0C1851 !important;
  border-radius: 10px !important;
  font-size: 18px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  padding: 10px 20px !important;
  @media (max-width: 800px) {
    font-size: 16px !important;
  }
}

.buttoncopy{
  background: none !important;
  border: none !important;
  color: #EBEDF2 !important;
  margin-left: 10px !important;
  padding: 0px !important;
}

.but2{
  margin-bottom: 20px;
}

.but3{
  border-radius: 15px !important;
  width: 100%;
  border: 3px solid ;
  margin-top:5%;
  border-color: #EBEDF2;
  background: transparent !important;
  color: #EBEDF2 !important;
  font-size: 18px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  @media (max-width: 800px) {
    font-size: 16px !important;
  }
}

.so_telefone {
  position: relative;
  font-size: 16px;
  text-align: center;
  font-family: 'Plus Jakarta Sans' !important;
  color: #EBEDF2 !important;
  margin: 5px;
  padding: 15px;
  justify-content: center;
  border-radius: 20px;
  background: linear-gradient(179deg, rgba(35, 51, 82, 0.25) 0%, rgba(10, 17, 48, 0.40) 100%);
  backdrop-filter: blur(7px);

}

.bot_tel{
  width: 50px;
  height: 50px;
  margin: 5px;
  padding: 5px;
}

.hero {

  font-family: 'Plus Jakarta Sans' !important;
  background: rgb(2, 0, 36);
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 35%,
    rgba(38, 31, 170, 1) 100%
  );
  @media (max-width: 700px) {
    padding-top: 30px;
  }
  .heading {
    font-family: 'Plus Jakarta Sans';
    font-size: 60px;
    font-weight: 500;
    color: #fff;
    @media (max-width: 700px) {
      font-size: 35px;
      line-height: 35px;
    }
  }
  button {
    border: 2px solid #fff !important;
    color: #fff !important;
    padding: 12px 50px !important;
    font-family: 'Plus Jakarta Sans' !important;
    font-size: 16px !important;
    font-weight: 500;
    border-radius: 0px !important;
    transition: transform 0.5s ease;
    &:hover {
      background: #fff !important;
      color: #261faa !important;
      transform: translateY(-10px);
    }
  }
  .token {
    font-family: 'Plus Jakarta Sans';
    font-size: 35px;
    font-weight: 300;
    color: #fff;
    @media (max-width: 700px) {
      font-size: 24px !important;
    }
  }
  .desc {
    font-family: 'Plus Jakarta Sans';
    font-size: 20px;
    font-weight: 300;
    color: rgba(244, 246, 248, 0.73);
    @media (max-width: 700px) {
      font-size: 24px !important;
    }
  }
}
.contact-us {
  .card {
    border-radius: 10px !important;
    padding: 20px !important;
    border: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important;
    .heading {
      font-family: 'Plus Jakarta Sans' !important;
      font-size: 35px;
      font-weight: 600;
      color: #000000;
      @media (max-width: 700px) {
        font-size: 24px !important;
      }
    }
    hr {
      border: 0 !important;
      background: #aa9d91 !important;
      height: 2px !important;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 16px !important;
      color: #aa9d91;
      @media (max-width: 700px) {
        font-size: 14px !important;
      }
    }
    .form-control {
      border: 1px solid #aa9d91 !important;
      border-radius: 2px !important;
      color: #595c64 !important;
      font-size: 16px !important;
      font-weight: 500 !important;
      font-family: 'Plus Jakarta Sans' !important;
      &::placeholder {
        color: #aa9d91 !important;
      }
      @media (max-width: 700px) {
        font-size: 14px !important;
      }
    }
    button {
      background: #000000 !important;
      border: 1px solid #000000;
      border-radius: 0px !important;
      padding: 8px 10px !important;
      font-family: 'Plus Jakarta Sans' !important;
      font-size: 18px !important;
      font-weight: 500 !important;
      color: #fff !important;
      @media (max-width: 700px) {
        font-size: 18px !important;
      }
      &:hover {
        background: #261faa !important;
        border: 1px solid #261faa !important;
      }
    }
  }
}

.sale {
  .align-right {
    text-align: right;
    font-family: 'Plus Jakarta Sans' !important;
    font-size: 20px;
  }

  .align-left {
    text-align: left;
    font-family: 'Plus Jakarta Sans' !important;
    font-size: 35px;
  }

  .card {
    border-radius: 10px !important;
    padding: 20px !important;
    border: 0 !important;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px,
      rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px,
      rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px !important;
    .heading {
      font-family: 'Plus Jakarta Sans' !important;
      display: inline-block;
      font-size: 35px;
      font-weight: 600;
      color: #000000;
      @media (max-width: 700px) {
        font-size: 24px !important;
      }
    }
    hr {
      border: 0 !important;
      background: #aa9d91 !important;
      height: 2px !important;
    }
    .balance {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 16px !important;
      color: #aa9d91;
      @media (max-width: 700px) {
        font-size: 14px !important;
      }
    }
    .input-wrapper {
      position: relative;
      .form-control {
        border: 0 !important;
        color: #595c64 !important;
        font-size: 24px !important;
        font-weight: 700 !important;
        font-family: 'Plus Jakarta Sans' !important;
        @media (max-width: 700px) {
          font-size: 18px !important;
        }
      }
      .token {
        position: absolute;
        right: 0;
        top: 0;
        color: #595c64 !important;
        font-size: 24px !important;
        font-weight: 700 !important;
        font-family: 'Plus Jakarta Sans' !important;
        margin-top: 10px;
        margin-right: 25px;
        @media (max-width: 700px) {
          font-size: 18px !important;
        }
      }
    }
    button {
      background: #000000 !important;
      border: 1px solid #000000;
      border-radius: 0px !important;
      padding: 10px !important;
      font-family: 'Plus Jakarta Sans' !important;
      font-size: 25px !important;
      font-weight: 500 !important;
      color: #fff !important;
      @media (max-width: 700px) {
        font-size: 18px !important;
      }
      &:hover {
        background: #261faa !important;
        border: 1px solid #261faa !important;
      }
    }
  }
}

.footer {
  margin-top: 20px;
  background: linear-gradient(90deg, rgba(17,83,201,1) 3%, rgba(12,24,81,1) 100%);
  padding: 5%;
  width: 100%;
  margin-top: 10%;

  .logo {
    font-size: 25px !important;
    font-family: 'Plus Jakarta Sans';
    color: #fff;
    font-weight: 500;
  }
  small {
    font-size: 14px !important;
    font-family: 'Plus Jakarta Sans';
    color: #fff;
    font-weight: 400;
  }
}

.flex {
  display: flex;
}
.flex-col {

  flex-direction: column;
}

.flex-col2 {
  display: flex;
  flex-direction: column;
}

.aic {
  align-items: center;
}
.ais{
  align-items: start;
}
.jc {
  justify-content: center;
}
.pointer{
  cursor: pointer;
}

.imagem_nft{
  position: fixed;
  height: 100%;
  width: 100%;
  background-image: url('Adventure.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:100%;
  object-fit: cover;
  padding-top: 12%;
  padding-left: 10%;
}

.imagem_nft2{
  position: fixed;
  height: 70%;
  width: 100%;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size:100%;
  object-fit: cover;
}


.box2{
  width: max-content;
  padding: 20px;
  padding-right: 50px;
  text-align: left;
  backdrop-filter: blur(7px);
  border-radius: 20px;
  background: linear-gradient(179deg, rgba(35, 51, 82, 0.15) 0%, rgba(10, 17, 48, 0.30) 100%);
}


.branco {
  margin: 5%;
  font-size: 14px !important;
  font-family: 'Plus Jakarta Sans';
  color: #fff;
  font-weight: 400;         
}


.sec-branco {
  font-size: 40px;
  font-weight: 600;
  font-family: 'Plus Jakarta Sans';
  color: #EBEDF2;
}

.sec-colorido
{
  font-size: 40px;
  font-weight: 600;
  font-family: 'Plus Jakarta Sans';
  background: linear-gradient(120deg,#fff 10%, #1153C9 80%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.sec-pequeno {
  word-break:break-all;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Plus Jakarta Sans';
  color: #EBEDF2;
}

.sec-pequeno1 {
  word-break:break-all;
  font-size: 22px;
  font-weight: 800;
  font-family: 'Plus Jakarta Sans';
  color: #EBEDF2;
}

.hero-page {
  
  position: fixed;
  display: flex;
  min-height: 930px;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(17,83,201,1) 3%, rgba(12,24,81,1) 100%);
  
// bloco 

  .wrap {
    ._block {
      //tirei esses porque nao me deixaba mudar o tamanho da caixa da isquerda
      //min-height: 500px;
      //width: 900px;
      align-items: flex-start;
      gap: 40px;
      @media (max-width: 800px) {
        flex-direction: column;
        align-items: stretch;
        padding: 30px 0;
      }
      
// caixa de mintagem

      .block-left-side {
        
        .box {
          block-size: auto;
          width: 100%;
          padding: 20px;
          text-align: left;
          backdrop-filter: blur(7px);
          border-radius: 20px;
          background: linear-gradient(179deg, rgba(35, 51, 82, 0.25) 0%, rgba(10, 17, 48, 0.40) 100%);


// butao seleccionar sua tribu
          .tribe-dropdown {
            padding: 13px;
            text-align: left;
            border-radius: 15px;
            background: #EBEDF2 !important;
            height: 100%;
            width: 100%;
            font-size: 18px;
            font-weight: 500;
            color: #0C1851 !important;
            padding-right: 20px;
        }




//titulo de mint section

            .sec-title {
              font-size: 25px;
              font-weight: 600;
              font-family: 'Plus Jakarta Sans';
              color: #ffffff;
            }
          }

         

//estilo mint section
// aqui funciono

          .user-info-sec {
            width: 100%;
            gap: 10px;
            align-items: center;
            justify-content: center;
            margin: 30px 0;
            color: #000000;
            font-size: 14px;
          }

            .u-data {
              flex: 1;
              gap: 8px;
              
              .copy-icon{
                cursor: pointer;
              }
              .u-img {
                height: 36px;
                width: 36px;
              }
              .u-meta {
                .lbl {
                  font-size: 14px;
                  font-weight: 600;
                  font-family: 'Plus Jakarta Sans';
                  line-height: 16px;
                  color: #000000;
                }

                .val {
                  margin: 0;
                  font-size: 14px;
                  font-family: 'Plus Jakarta Sans';
                  font-weight: 400;
                  line-height: 16px;
                }
              }

// borde inutil

              &:first-child {
                .u-meta{
                  .link{
                    &:hover{
                      cursor: pointer;
                      color: #595c64;
                     }
                  }
                  .val {
                    margin-right: 4px;
                
                  }
                }
              }
            }
          }

          .numb-sec {
// sao os 1-1000
            .numbs {
              font-size: 16px;
              font-weight: bold;
              color: #000000;
              font-family: 'Plus Jakarta Sans';
            }
          }

    

// botao de seleccionar a cantidade

          .mint-price-sec {
            border-radius: 15px;
            width: 100%;
            border: 3px solid ;
            border-color: #EBEDF2;
            
            .eth-val {
              font-size: 18px;
              font-weight: 600;
              color: rgb(255, 255, 255);
              padding: 8px;
              padding-left: 25px;
            }

// o cero de seleccionar

            .e-numb {
              height: 100%;
              width: 100%; //agora ocupa todo o botao
              padding: 10px;
              font-size: 18px;
              font-weight: 500;
              color: #EBEDF2;
              text-align: center;
            }
          }
          .slug {
           margin: 20px 0;

// texto public sale

            .slug-txt {
              font-size: 16px;
              font-weight: 500;
              color: #ffffff;
              margin: 0 px;
              font-family: 'Plus Jakarta Sans';
              text-align: left;
            }
          }

          .butoes_h {
            font-size: 14px !important;
              font-family: 'Plus Jakarta Sans';
              color: #fff;
              font-weight: 400;
              text-align: center;
              padding-bottom: 10px;
          }

          .bold_butoes{
            font-weight: bold;
          }


          .butoes_h2 {
            font-size: 14px !important;
              font-family: 'Plus Jakarta Sans';
              color: #fff;
              font-weight: 400;
              text-align: center;
          }

// botoes de approve

          .actions {
            gap: 14px;
            .btn-mint,

// ese sim que muda o botao de approve

            .btn-referral {
              width: 100%;
              font-size: 16px;
              font-weight: 600;
              border-radius: 15px !important;
              color: #0C1851!important;
              background: #EBEDF2 !important;             
              &:hover {
                opacity: 0.8;
              }
            }

          }
          .socials{
            margin-top: 30px;
            gap: 10px;
            .icon{
              height: 28px;
              width: 28px;
              background: #000000;
              border-radius: 50%;
              padding: 4px;
              &:hover{
                cursor: pointer;
              }
              svg{
                path{
                  fill: #ffffff;
                }
              }
            }
          }
        }

//imagem nft geraldo

        .block-right-side {
          background: linear-gradient(179deg, rgba(35, 51, 82, 0.25) 0%, rgba(10, 17, 48, 0.40) 100%);
          backdrop-filter: blur(7px);
          border-radius: 20px;
          position: relative;
          flex: 1;
          left:60%;
          right:10%;
          max-width: 300px;
          block-size: auto;
          
          .titulo_texto_tribu {
            font-family: 'Plus Jakarta Sans';
            font-size: 16px;
              color: #fff;
            font-weight: bold;
            
          }

          .texto_tribu {
            font-family: 'Plus Jakarta Sans';
            font-size: 14px;
              color: #fff;
              padding: 20px;

              span:not(:first-child){
                display: block;
                margin: 25px 0px;
              }
          }
      }
    } //aqui acaba os estilos da parte "wrap"
} //aqui acaba os estilos da hero-page
  
// RESPONSIVENESS FIX
@media (max-width:1299px){
  .hero-page .wrap ._block .block-left-side .box {
      margin-top: 2vw;
      margin-bottom: 0px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
  }
  .wrap.wrapWidth.flex.aic.jc {
      padding-left: 0px;
      justify-content: flex-start;
      width: 100%;
  }
  p.branco {
      height: 50px;
      padding-top: 10px;
      align-self: flex-end;
      margin-left: auto;
      margin-bottom: 3.1vw;
  }

  .hero-page {
      flex-direction:column;
      position: static;
  }
  .imagem_nft {
      position: static;
      height: 100%;
  }
}

@media (max-width:1200px){
  .hero-page .wrap .block-right-side{
    position: static;
  }
}



@media (min-width: 800px) and (max-width: 1300px){
  .imagem_nft{
      height: 80vh;
  }
}


//troquei pra nao quebrar no telefone
@media (min-width:1200px){


  span.sec-branco,.sec-colorido {
      font-size: 2.2vw;
  }
  .hero-page .wrap ._block .butoes_h2,
  p.branco,
  .hero-page .wrap ._block .butoes_h,
  .hero-page .wrap .block-right-side .texto_tribu,
  .footer small{
      font-size: 0.9vw!important;
  }
  button.adapter-react-button.btn.btn-primary,
  h1.sec-pequeno,
  .hero-page .wrap ._block .slug .slug-txt,
  .hero-page .wrap ._block .actions .btn-mint,
  .hero-page .wrap ._block .actions .btn-referral,
  .hero-page .wrap ._block .mint-price-sec .e-numb,
  .but3,
  .hero-page .wrap .block-right-side .titulo_texto_tribu{
      font-size: 1vw;
  }

  .hero-page .wrap .block-right-side{
    max-width: 22vw;}}

@media (min-width:1300px){
  .hero-page .wrap ._block {
      min-height: auto;
  }
  
  .hero-page .wrap ._block .block-left-side .box {
      margin-top: 2vw;
      margin-bottom: 0px;
      block-size: auto;
      height: 56vh;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
  }
  .hero-page .wrap ._block .butoes_h{
    margin:auto;
  }
  
  .wrap.wrapWidth.flex.aic.jc {
      padding-left: 0px;
      justify-content: flex-start;
      width: 100%;
  }
  
  .imagem_nft {
      height: 84vh;
      padding-top: 8vw;
  }
  
  .hero-page {
      min-height: 100vh;
      height: auto;
  }
  
  p.branco {
      height: 50px;
      padding-top: 0px;
      align-self: flex-end;
      margin-left: auto;
      margin-bottom: 3.1vw;
  }
  
  }
  

@media (min-width: 1700px){
  .hero-page .wrap ._block .block-left-side .box{
    height: 51vh;
    min-height: 400px;
  }
}

.footer2 {
  position: fixed;
  background-color: #000000;
  width: 100%;
  height: 100%;
}

.hero-page {
    position: static;
    flex-direction: column;
}

.imagem_nft {
    position: static;
    height: 100%;
    padding-bottom: 40px;
}

.hero-page .wrap ._block .block-left-side .box {
    height: auto;
}

.hero-page .wrap .block-right-side {
    margin-top: 2vw;
}

@media (min-width:900px){
  .so_telefone {
    display: none;
  }
}


.hero-page {
    min-height: 100vh;
    justify-content: stretch;
}

.imagem_nft {
    background-size: cover;
}

.imagem_nft {
    flex-grow: 1;
}

.footer {
    margin-top: 0px;
}

@media (max-width:800px){
     .box2{
    width:97%;
    padding-right: 20px;   
  }  
  .wrap.wrapWidth.flex.aic.jc {
        flex-direction: column;
    }
    
    .hero-page .wrap .block-right-side{
        position: static;
        max-width:none;
    }
    
    .imagem_nft {
        padding-top: 120px;
        padding-left: 20px;
        padding-right: 20px;
        background-position:center;
    }
}